import * as React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { filtrarPorMunicipio, filtrarPorUf } from './helpers';
import { ReferenciaLocalizada } from '../../../domain/referencia-localizada.domain';
import { locais } from '../../../data/locais.data';
import { Municipio } from '../../../domain/municipio.domain';
import { UnidadeFederativa } from '../../../domain/unidade-federativa.domain';
import { MarcadorMapa } from './display-map.types';
import { obterFronteiras } from '../../../domain/acl/geocode.helpers';
import { ajustarFronteiras } from '../../../domain/acl/google-maps.helpers';
import { GeocodeClient } from '../../../services/geocode.service';

interface PaineisStatus {
  [key: string]: boolean;
}

interface UseDisplayMapArgs {
  municipio?: Municipio | null;
  unidadeFederativa?: UnidadeFederativa | null;
}

export interface UseDisplayHook {
  carregando: boolean;
  raioEmMetros: number;
  marcadores: MarcadorMapa[];
  mapa: google.maps.Map | null;
  onGoogleMapLoad: (map: google.maps.Map) => void;
  onGoogleMapUnmount: () => void;
}

export const useDisplayMap = ({
  municipio,
  unidadeFederativa,
}: UseDisplayMapArgs): UseDisplayHook => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY!,
    language: 'pt-br',
  });

  // TODO: (ID) Utilizar a referencia do mapa
  const [mapa, setMapa] = React.useState<google.maps.Map | null>(null);

  // eslint-disable-next-line
  const [raioEmMetros, setRaioEmMetros] = React.useState(500);
  const [paineisStatus, setPaineisStatus] = React.useState<PaineisStatus>({});

  const onGoogleMapLoad = React.useCallback(async (map: google.maps.Map) => {
    const response = await GeocodeClient.doEndereco('Brasil');

    const fronteiras = obterFronteiras(response);
    map && ajustarFronteiras(map, fronteiras);

    // TODO: (ID) Isso parece não funcionar
    setMapa(map);
  }, []);

  const onGoogleMapUnmount = React.useCallback(() => {
    setMapa(null);
  }, []);

  const alternarStatusPainel = (referencia: ReferenciaLocalizada) => {
    const novoStatus = !paineisStatus[referencia.rotulo];

    setPaineisStatus({
      ...paineisStatus,
      [referencia.rotulo]: novoStatus,
    });
  };

  const marcadores: MarcadorMapa[] = locais
    .filter((local) => filtrarPorUf(local, unidadeFederativa))
    .filter((local) => filtrarPorMunicipio(local, municipio))
    .map((local) => {
      const aberto = !!paineisStatus[local.rotulo];

      return {
        uuid: local.uuid,
        rotulo: local.rotulo,
        posicao: local.coordenadas,
        painelInfo: {
          aberto,
          alternarAberto: () => alternarStatusPainel(local),
        },
        servicosHabilitacao: local.servicosHabilitacao ?? [],
        categoriasHabilitacao: local.categoriasHabilitacao ?? [],
      };
    });

  return {
    carregando: !isLoaded,
    raioEmMetros,
    marcadores,
    mapa,
    onGoogleMapLoad,
    onGoogleMapUnmount,
  };
};
