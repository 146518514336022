import { ChangeEventHandler } from 'react';
import { obterOpcoesMunicipioPorUf } from '../helpers';
import { Municipio } from '../../../domain/municipio.domain';
import { UnidadeFederativa } from '../../../domain/unidade-federativa.domain';

interface SelectMunicipioProps {
  municipio?: Municipio | null;
  unidadeFederativa?: UnidadeFederativa | null;
  onChange: ChangeEventHandler;
}

export const SelectMunicipio = ({
  municipio,
  unidadeFederativa,
  onChange,
}: SelectMunicipioProps) => {
  const opcoesMunicipio = unidadeFederativa
    ? obterOpcoesMunicipioPorUf(unidadeFederativa)
    : [];

  return (
    <div className="input-group mb-3 disabled">
      <span className="input-group-text">Cidade</span>
      <select
        className="form-select"
        value={municipio?.nome ?? ''}
        onChange={onChange}
      >
        <option>Selecione</option>
        {opcoesMunicipio}
      </select>
    </div>
  );
};
