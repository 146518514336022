import { UF_BA, UF_RJ } from './unidades-federativas.data';
import { Municipio } from '../domain/municipio.domain';

export const municipios: Municipio[] = [
  {
    nome: 'Salvador',
    unidadeFederativa: UF_BA,
    capital: true,
  },
  {
    nome: 'Lauro de Freitas',
    unidadeFederativa: UF_BA,
    capital: false,
  },
  {
    nome: 'Rio de Janeiro',
    unidadeFederativa: UF_RJ,
    capital: true,
  },
  {
    nome: 'Petropólis',
    unidadeFederativa: UF_RJ,
    capital: false,
  },
];
