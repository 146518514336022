import { Municipio } from '../../../domain/municipio.domain';
import { ReferenciaLocalizada } from '../../../domain/referencia-localizada.domain';
import { UnidadeFederativa } from '../../../domain/unidade-federativa.domain';

export const filtrarPorUf = (
  local: ReferenciaLocalizada,
  unidadeFederativa?: UnidadeFederativa | null
) => !!unidadeFederativa && local.siglaUf === unidadeFederativa.sigla;

export const filtrarPorMunicipio = (
  local: ReferenciaLocalizada,
  municipio?: Municipio | null
) => !municipio || local.nomeCidade === municipio.nome;

export const OPCOES_CIRCULO = {
  fillColor: 'coral',
  fillOpacity: 0.3,
  strokeWeight: 2,
  strokeColor: 'coral',
  clickable: false,
  editable: false,
  zIndex: 1,
};
