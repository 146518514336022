import { EventoAnalytics } from '../domain/evento-analytics.domain';
import { MarcadorMapa } from './components/map/display-map.types';

interface PayloadEventoClickReferenciaMapa {
  acao: 'ABRIR' | 'FECHAR';
  idReferencia: string;
  idUsuario?: string | null;
}

export const criarEventoClickEmReferenciaMapa = (
  marcador: MarcadorMapa
): EventoAnalytics<PayloadEventoClickReferenciaMapa> => {
  const acao = marcador.painelInfo.aberto ? 'FECHAR' : 'ABRIR';
  const idReferencia = marcador.uuid;

  return {
    rotulo: 'Click em Referência de Local no Mapa',
    nome: 'auto-click-referencia-mapa',
    acao: 'Click em Referência de Local no Mapa',
    payload: {
      acao,
      idReferencia,
    },
  };
};

interface PayloadEventoVisualizacaoPagina {
  view: string;
}

export const criarEventoVisualizacaoPagina = (
  view: string
): EventoAnalytics<PayloadEventoVisualizacaoPagina> => {
  return {
    rotulo: 'Visualização de View',
    nome: 'auto-visualizao-view',
    acao: 'Navegação pelo App',
    payload: {
      view,
    },
  };
};

interface PayloadEventoClickBotaoAbrirModalSolicitarOrcamento {
  idReferencia: string;
  nome: string;
}

export const criarEventoClickBotaoAbrirModalSolicitarOrcamento = (
  marcador: MarcadorMapa
): EventoAnalytics<PayloadEventoClickBotaoAbrirModalSolicitarOrcamento> => {
  const idReferencia = marcador.uuid;
  const nome = marcador.rotulo;

  return {
    rotulo: 'Click no Botão de Abrir Modal para Solicitar Orçamento',
    nome: 'auto-click-botao-abrir-modal-solicitar-orcamento',
    acao: 'Click no Botão de Abrir Modal para Solicitar Orçamento',
    payload: {
      idReferencia,
      nome,
    },
  };
};

// TODO: (ID) Aqui começam a surgiri os exemplos de repetição de código
interface PayloadEventoClickBotaoAbrirModalSouProprietario {
  idReferencia: string;
  nome: string;
}

export const criarEventoClickBotaoAbrirModalSouProprietario = (
  marcador: MarcadorMapa
): EventoAnalytics<PayloadEventoClickBotaoAbrirModalSouProprietario> => {
  const idReferencia = marcador.uuid;
  const nome = marcador.rotulo;

  return {
    rotulo: 'Click no Botão de Abrir Modal para Assumir Propriedade',
    nome: 'auto-click-botao-abrir-modal-assumir-propriedade',
    acao: 'Click no Botão de Abrir Modal para Assumir Propriedade',
    payload: {
      idReferencia,
      nome,
    },
  };
};
