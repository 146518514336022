import { Fronteiras } from '../fronteiras.domain';

export const ajustarFronteiras = (
  mapa: google.maps.Map,
  fronteiras: Fronteiras
) => {
  const { sudoeste, nordeste } = fronteiras;

  mapa.fitBounds(
    new google.maps.LatLngBounds(
      {
        lat: sudoeste.latitude,
        lng: sudoeste.longitude,
      },
      {
        lat: nordeste.latitude,
        lng: nordeste.longitude,
      }
    )
  );
};
