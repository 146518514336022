import { UnidadeFederativa } from '../domain/unidade-federativa.domain';

export const UF_AC: UnidadeFederativa = {
  sigla: 'AC',
  nome: 'Acre',
};

export const UF_BA: UnidadeFederativa = {
  sigla: 'BA',
  nome: 'Bahia',
};

export const UF_RJ: UnidadeFederativa = {
  sigla: 'RJ',
  nome: 'Rio de Janeiro',
};

export const unidadesFederativas: UnidadeFederativa[] = [UF_AC, UF_BA, UF_RJ];
