import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';

import { HomeView } from '../home/home.view';
import { NavbarTop } from '../../shared/components/top-menu/top-menu.component';
import { AppModal } from '../../shared/components/app-modal/app-modal.component';

const MainDiv = styled.div`
  background-image: url('${process.env.PUBLIC_URL}/img/background.jpeg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AppView = () => {
  return (
    <MainDiv>
      <NavbarTop />
      <Container>
        <HomeView />
      </Container>
      <AppModal />
    </MainDiv>
  );
};

export { AppView };
