import mixpanel from 'mixpanel-browser';
import { EventoAnalytics } from '../domain/evento-analytics.domain';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN!, {
  debug: process.env.NODE_ENV === 'development',
  track_pageview: false,
  persistence: 'localStorage',
});

export const MixpanelClient = {
  track: (evento: EventoAnalytics) => {
    mixpanel.track(evento.nome, evento.payload ?? {});
  },
};
