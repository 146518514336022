type SiglaCategoriaHabilitacao = 'ACC' | 'A' | 'B' | 'C' | 'D' | 'E';

export interface CategoriaHabilitacao {
  sigla: SiglaCategoriaHabilitacao;
  nome: string;
  descricao: string[];
  permitidosCategoria: string[];
  proibidos: string[];
  permitidosExtensao?: string[];
}

const obterCategoriaAcc = (): CategoriaHabilitacao => ({
  sigla: 'ACC',
  nome: 'Autorização para Conduzir Ciclomotor',
  descricao: [
    'Permite veículos de duas rodas com até 50 cilindradas e limite de velocidade de 50km/hora.',
  ],
  permitidosCategoria: ['Cinquentinha'],
  proibidos: [
    'Motocicletas',
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
    'Ônibus',
    'Micro-ônibus',
    'Vans',
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
});

const obterCategoriaA = (): CategoriaHabilitacao => ({
  sigla: 'A',
  nome: 'Categoria A',
  descricao: [
    'Permite veículos de duas ou três rodas, com ou sem carro lateral, com mais que 50 cilindradas.',
  ],
  permitidosCategoria: ['Motocicletas'],
  proibidos: [
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
    'Ônibus',
    'Micro-ônibus',
    'Vans',
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
});

const obterCategoriaB = (): CategoriaHabilitacao => ({
  sigla: 'B',
  nome: 'Categoria B',
  descricao: [
    'Permite veículos de quatro rodas com até 3,5 toneladas de peso bruto total e capacidade para até oito passageiros, além do motorista (nove ocupantes no total).',
    'Permite o acoplamento de reboques e semirreboques, desde que o conjunto da extensão e veículo não ultrapasse o peso ou lotação máximos definidos. É proibida a condução de carros puxando trailers (Categoria E).',
    'Permite motor-casas (motorhomes) que não excedam 6 toneladas e não transportem mais que nove ocupantes.',
  ],
  permitidosCategoria: [
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
  ],
  proibidos: [
    'Cinquentinha',
    'Motocicletas',
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
    'Ônibus',
    'Micro-ônibus',
    'Vans',
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
});

const obterCategoriaC = (): CategoriaHabilitacao => ({
  sigla: 'C',
  nome: 'Categoria C',
  descricao: [
    'Permite todos os tipos de automóveis da Categoria B.',
    'Permite veículos de carga, não articulados, com mais de 3,5 toneladas de peso bruto total.',
  ],
  permitidosCategoria: [
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
  ],
  permitidosExtensao: [
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
  ],
  proibidos: [
    'Cinquentinha',
    'Motocicletas',
    'Ônibus',
    'Micro-ônibus',
    'Vans',
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
});

const obterCategoriaD = (): CategoriaHabilitacao => ({
  sigla: 'D',
  nome: 'Categoria D',
  descricao: [
    'Permite todos os tipos de automóveis das Categoria B e C.',
    'Permite conduzir veículos para o transporte de passageiros que acomodem mais de 8 passageiros.',
  ],
  permitidosCategoria: ['Ônibus', 'Micro-ônibus', 'Vans'],
  permitidosExtensao: [
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
  ],
  proibidos: [
    'Cinquentinha',
    'Motocicletas',
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
});

const obterCategoriaE = (): CategoriaHabilitacao => ({
  sigla: 'E',
  nome: 'Categoria E',
  descricao: [
    'Permite todos os tipos de automóveis das Categorias B, C e D.',
    'Permite conduzir veículos com unidades acopladas que excedam 6.000 kg (6 toneladas).',
  ],
  permitidosCategoria: [
    'Carretas com reboques e semirreboques articulados',
    'Caminhões com reboques e semirreboques articulados',
  ],
  permitidosExtensao: [
    'Carros de passeio',
    'Quadriciclos',
    'SUVs',
    'Acomplamento de reboques e semorreboques',
    'Caminhões',
    'Tratores',
    'Máquinas agrícolas',
    'Máquinas de movimentação de carga',
    'Ônibus',
    'Micro-ônibus',
    'Vans',
  ],
  proibidos: ['Cinquentinha', 'Motocicletas'],
});

type CategoriasHabilitacao = {
  [key in SiglaCategoriaHabilitacao]: CategoriaHabilitacao;
};

export const CATEGORIAS_CNH: CategoriasHabilitacao = {
  ACC: obterCategoriaAcc(),
  A: obterCategoriaA(),
  B: obterCategoriaB(),
  C: obterCategoriaC(),
  D: obterCategoriaD(),
  E: obterCategoriaE(),
};
