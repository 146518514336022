export type TipoServicoHabilitacao =
  | 'PRIMEIRA_HABILITACAO'
  | 'ADICAO_CATEGORIA'
  | 'ATUALIZACAO_HABILITACAO'
  | 'RECICLAGEM'
  | 'TREINAMENTO_HABILITADOS'
  | 'RENOVACAO'
  | 'MUDANCA_CATEGORIA'
  | 'RECONHECIMENTO_PERCURSO'
  | 'AULA_PRATICA_EXTRA'
  | 'CURSO_TEORICO'
  | 'TAXA_MONITORAMENTO'
  | 'MOTO_TAXI'
  | 'MOTO_FRETISTA'
  | 'TRANSPORTE_COLETIVO_PASSAGEIROS'
  | 'TRANSPORTE_ESCOLAR'
  | 'ESPCECIALIZACAO_VEICULOS_PASSAGEIROS'
  | 'TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS'
  | 'VEICULOS_EMERGENCIA'
  | 'RECLASSIFICACAO'
  | 'DIRECAO_DEFENSIVA';

export interface ServicoHabilitacao {
  tipo: TipoServicoHabilitacao;
  nome: string;
  // TODO: (ID) Elipsis (regra de visualização)
  nomeCurto?: string;
  descricao: string;
  careceEsclarecimentos?: boolean;
  ocultar?: boolean;
}

const PRIMEIRA_HABILITACAO: ServicoHabilitacao = {
  tipo: 'PRIMEIRA_HABILITACAO',
  nome: 'Primeira Habilitação',
  descricao: 'Destinado a novos condutores.',
};

const ADICAO_CATEGORIA: ServicoHabilitacao = {
  tipo: 'ADICAO_CATEGORIA',
  nome: 'Adição de Categoria',
  descricao:
    'Destinado a condutores habilitadas que desejam adicionar uma nova Categoria.',
};

const RECICLAGEM: ServicoHabilitacao = {
  tipo: 'RECICLAGEM',
  nome: 'Reciclagem',
  descricao:
    'Destinado a condutores infratores que tiveram sua habilitação suspensa.',
};

const ATUALIZACAO_HABILITACAO: ServicoHabilitacao = {
  tipo: 'ATUALIZACAO_HABILITACAO',
  nome: 'Atualização de Habilitação',
  descricao: 'Carece esclarecimentos.',
  careceEsclarecimentos: true,
  ocultar: true,
};

const TREINAMENTO_HABILITADOS: ServicoHabilitacao = {
  tipo: 'TREINAMENTO_HABILITADOS',
  nome: 'Treinamento para Habilitados',
  descricao:
    'Destinado a condutores habilitados que possuem dificuldades para dirigir ou desejam praticar com instrutores capacitados.',
};

const RENOVACAO: ServicoHabilitacao = {
  tipo: 'RENOVACAO',
  nome: 'Renovação',
  descricao:
    'Destinado a condutores habilitados que precisam passar por treinamento obrigatório para renovar a habilitação.',
  careceEsclarecimentos: true,
  ocultar: true,
};

const MUDANCA_CATEGORIA: ServicoHabilitacao = {
  tipo: 'MUDANCA_CATEGORIA',
  nome: 'Mudança de Categoria',
  descricao:
    'Destinado a condutores habilitados que desejam mudar a categoria da habilitação.',
  careceEsclarecimentos: true,
  ocultar: true,
};

const RECONHECIMENTO_PERCURSO: ServicoHabilitacao = {
  tipo: 'RECONHECIMENTO_PERCURSO',
  nome: 'Reconhecimento de Percurso',
  descricao:
    'Aula específica destinada a fazer o reconhecimento do percurso da prova prática.',
};

const AULA_PRATICA_EXTRA: ServicoHabilitacao = {
  tipo: 'AULA_PRATICA_EXTRA',
  nome: 'Aula Extra',
  descricao:
    'Aula prática adicional para treinamento, seja de habilitados ou não-habilitados.',
};

const CURSO_TEORICO: ServicoHabilitacao = {
  tipo: 'CURSO_TEORICO',
  nome: 'Curso Teórico',
  descricao:
    'Aulas teóricas obrigatórias para os cursos de Primeira Habilitação e Reciclagem.',
  careceEsclarecimentos: true,
};

const TAXA_MONITORAMENTO: ServicoHabilitacao = {
  tipo: 'TAXA_MONITORAMENTO',
  nome: 'Taxa de Monitoramento',
  descricao: 'Carece de esclarecimentos',
  careceEsclarecimentos: true,
  ocultar: true,
};

const MOTO_TAXI: ServicoHabilitacao = {
  tipo: 'MOTO_TAXI',
  nome: 'Moto Táxi',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: true,
};

const MOTO_FRETISTA: ServicoHabilitacao = {
  tipo: 'MOTO_FRETISTA',
  nome: 'Moto Fretista',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: true,
};

const TRANSPORTE_COLETIVO_PASSAGEIROS: ServicoHabilitacao = {
  tipo: 'TRANSPORTE_COLETIVO_PASSAGEIROS',
  nome: 'Transporte Coletivo de Passageiros',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: false,
};

const TRANSPORTE_ESCOLAR: ServicoHabilitacao = {
  tipo: 'TRANSPORTE_ESCOLAR',
  nome: 'Transporte Escolar',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: false,
};

const ESPCECIALIZACAO_VEICULOS_PASSAGEIROS: ServicoHabilitacao = {
  tipo: 'ESPCECIALIZACAO_VEICULOS_PASSAGEIROS',
  nome: 'Especialização na condução de veículos de passageiros',
  nomeCurto: 'Espec. Veículos de Passageiros',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: false,
};

const TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS: ServicoHabilitacao = {
  tipo: 'TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS',
  nome: 'Transporte Rodoviário',
  descricao: 'Transporte rodoviário / transportador de produtos perigosos',
  careceEsclarecimentos: true,
  ocultar: false,
};

const VEICULOS_EMERGENCIA: ServicoHabilitacao = {
  tipo: 'VEICULOS_EMERGENCIA',
  nome: 'Veículos de Emergência',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: false,
};

const RECLASSIFICACAO: ServicoHabilitacao = {
  tipo: 'RECLASSIFICACAO',
  nome: 'Reclassificação da Habilitação',
  descricao: '',
  careceEsclarecimentos: true,
  ocultar: false,
};

const DIRECAO_DEFENSIVA: ServicoHabilitacao = {
  tipo: 'DIRECAO_DEFENSIVA',
  nome: 'Direção Defensiva',
  descricao:
    'Destinado principalmente para empresas que desejam treinar e certificar seus motoristas',
  careceEsclarecimentos: true,
  ocultar: false,
};

type ServicosHabilitacao = {
  [key in TipoServicoHabilitacao]: ServicoHabilitacao;
};

export const SERVICOS_HABILITACAO: ServicosHabilitacao = {
  PRIMEIRA_HABILITACAO: PRIMEIRA_HABILITACAO,
  ADICAO_CATEGORIA: ADICAO_CATEGORIA,
  RECICLAGEM: RECICLAGEM,
  ATUALIZACAO_HABILITACAO: ATUALIZACAO_HABILITACAO,
  TREINAMENTO_HABILITADOS: TREINAMENTO_HABILITADOS,
  RENOVACAO: RENOVACAO,
  MUDANCA_CATEGORIA: MUDANCA_CATEGORIA,
  RECONHECIMENTO_PERCURSO: RECONHECIMENTO_PERCURSO,
  AULA_PRATICA_EXTRA: AULA_PRATICA_EXTRA,
  CURSO_TEORICO: CURSO_TEORICO,
  TAXA_MONITORAMENTO: TAXA_MONITORAMENTO,
  MOTO_TAXI: MOTO_TAXI,
  MOTO_FRETISTA: MOTO_FRETISTA,
  TRANSPORTE_COLETIVO_PASSAGEIROS: TRANSPORTE_COLETIVO_PASSAGEIROS,
  TRANSPORTE_ESCOLAR: TRANSPORTE_ESCOLAR,
  ESPCECIALIZACAO_VEICULOS_PASSAGEIROS: ESPCECIALIZACAO_VEICULOS_PASSAGEIROS,
  TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS:
    TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS,
  VEICULOS_EMERGENCIA: VEICULOS_EMERGENCIA,
  RECLASSIFICACAO: RECLASSIFICACAO,
  DIRECAO_DEFENSIVA: DIRECAO_DEFENSIVA,
};
