import { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { SelectMunicipio } from './select-municipio.component';
import { SelectUnidadeFederativa } from './select-unidade-federativa.component';
import { Municipio } from '../../../domain/municipio.domain';
import { UnidadeFederativa } from '../../../domain/unidade-federativa.domain';

export const CalloutTitle = () => (
  <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
    Encontre Sua Auto Escola
  </h1>
);

export const CalloutLead = () => (
  <p className="lead">A sua jornada começa aqui!</p>
);

interface CalloutFormProps {
  municipio?: Municipio | null;
  unidadeFederativa?: UnidadeFederativa | null;
  selecionarUnidadeFederativa: ChangeEventHandler;
  selecionarMunicipio: ChangeEventHandler;
}

export const CalloutForm = ({
  unidadeFederativa,
  municipio,
  selecionarUnidadeFederativa,
  selecionarMunicipio,
}: CalloutFormProps) => {
  return (
    <div>
      <SelectUnidadeFederativa
        unidadeFederativa={unidadeFederativa}
        onChange={selecionarUnidadeFederativa}
      />
      <SelectMunicipio
        municipio={municipio}
        unidadeFederativa={unidadeFederativa}
        onChange={selecionarMunicipio}
      />
    </div>
  );
};

export const CalloutCard = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 10px;
`;

interface CalloutProps {
  municipio?: Municipio | null;
  unidadeFederativa?: UnidadeFederativa | null;
  selecionarUnidadeFederativa: ChangeEventHandler;
  selecionarMunicipio: ChangeEventHandler;
}

export const Callout = ({
  municipio,
  unidadeFederativa,
  selecionarUnidadeFederativa,
  selecionarMunicipio,
}: CalloutProps) => {
  return (
    <CalloutCard>
      <CalloutTitle />
      <CalloutLead />
      <CalloutForm
        municipio={municipio}
        unidadeFederativa={unidadeFederativa}
        selecionarUnidadeFederativa={selecionarUnidadeFederativa}
        selecionarMunicipio={selecionarMunicipio}
      />
    </CalloutCard>
  );
};
