import ReactDOM from 'react-dom/client';
import { AppView } from './views/app/app.view';

import 'bootstrap/dist/css/bootstrap.min.css';

const rootElement = document.querySelector('#root');
const root = ReactDOM.createRoot(rootElement!);

const App = () => {
  return <AppView />;
};

root.render(<App />);
