import { EventoAnalytics } from '../../../domain/evento-analytics.domain';

interface PayloadEventoCancelarEnvioSolicitacaoOrcamento {
  uuidEmpresa?: string;
  nomeEmpresa?: string;
}

export const criarEventoCancelarEnvioSolicitacaoOrcamento = (
  payload: PayloadEventoCancelarEnvioSolicitacaoOrcamento
): EventoAnalytics<PayloadEventoCancelarEnvioSolicitacaoOrcamento> => {
  return {
    rotulo: 'Cancelar Envio Solicitação de Orçamento',
    nome: 'auto-cancelar-envio-solicitacao-orcamento',
    acao: 'Click no botão Cancelar o preenchimento do formulário de Solicitação de Orçamento',
    payload,
  };
};
