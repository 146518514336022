import axios from 'axios';

interface FormularioSolicitacaoOrcamento {
  nome: string;
  email: string;
  telefone: string;
  maioridade: boolean;
  categorias: string[];
  servicos: string[];
  nomeEmpresa?: string;
  emailEmpresa?: string;
}

interface EnviarEmailConfiguracao {
  antiSpamToken: string;
  remetente?: string;
  assunto?: string;
  template?: {
    titulo?: string;
    rodape?: string;
  };
}

interface EnviarEmailArgs<Formulario> {
  dadosFormulario: Formulario;
  configuracao: EnviarEmailConfiguracao;
}

export const FormsparkClient = {
  enviarSolicitacaoOrcamento: async ({
    dadosFormulario,
    configuracao,
  }: EnviarEmailArgs<FormularioSolicitacaoOrcamento>) => {
    const template = configuracao.template ?? {};

    return await axios.post('https://submit-form.com/njfg1K6C', {
      ...dadosFormulario,
      maioridade: dadosFormulario.maioridade ? 'Sim' : 'Não',
      'cf-turnstile-response': configuracao.antiSpamToken,
      _email: {
        from: dadosFormulario.nome,
        subject: configuracao.assunto,
        ...template,
      },
    });
  },
};
