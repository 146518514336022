import * as React from 'react';
import { ReferenciaGoogleMaps } from '../../domain/acl/google-maps.acl';
import { Coordenadas } from '../../domain/coordenadas.domain';
import { unidadesFederativas } from '../../data/unidades-federativas.data';
import { UnidadeFederativa } from '../../domain/unidade-federativa.domain';
import { Municipio } from '../../domain/municipio.domain';
import { municipios } from '../../data/municipios.data';
import { ZOOM_LOCAL } from '../../shared/constants';
import { MixpanelClient } from '../../services/mixpanel.service';
import {
  criarEventoLocalizacaoUsuarioNegada,
  criarEventoLocalizacaoUsuarioPermitida,
} from './analytics';
import { GeocodeClient } from '../../services/geocode.service';

interface UsePosicaoAtualArgs {
  mapa: google.maps.Map | null;
  setUnidadeFederativa: (uf: UnidadeFederativa | null) => void;
  setMunicipio: (m: Municipio | null) => void;
}

export const usePosicaoAtual = ({
  mapa,
  setMunicipio,
  setUnidadeFederativa,
}: UsePosicaoAtualArgs) => {
  const [posicaoUsuario, setPosicaoUsuario] =
    React.useState<ReferenciaGoogleMaps | null>(null);

  const obterLocalizacaoUsuario = async (coordenadas: Coordenadas) => {
    MixpanelClient.track(criarEventoLocalizacaoUsuarioPermitida());

    const response = await GeocodeClient.dasCoordenadas(coordenadas);

    const componenteMunicipio = response.results
      .find((it) => it.types.includes('locality'))
      ?.address_components.find((it) => it.types.includes('locality'));

    const componenteUf = response.results
      .find((it) => it.types.includes('administrative_area_level_1'))
      ?.address_components.find((it) =>
        it.types.includes('administrative_area_level_1')
      );

    if (componenteUf) {
      const match = unidadesFederativas.find(
        (it) => it.sigla === componenteUf.short_name
      );
      setUnidadeFederativa(match ?? null);
    }

    if (componenteMunicipio) {
      const match = municipios.find(
        (it) => it.nome === componenteMunicipio.long_name
      );
      setMunicipio(match ?? null);
    }
  };

  const obterLocalizacaoUsuarioCallback = React.useCallback(
    obterLocalizacaoUsuario,
    [setMunicipio, setUnidadeFederativa]
  );

  const handleSucessoPosicaoAtual = async (
    mapa: google.maps.Map,
    posicao: GeolocationPosition
  ) => {
    const {
      coords: { latitude, longitude },
    } = posicao;

    obterLocalizacaoUsuarioCallback({
      latitude,
      longitude,
    });

    mapa.setCenter({
      lat: latitude,
      lng: longitude,
    });

    mapa.setZoom(ZOOM_LOCAL);

    setPosicaoUsuario({
      lat: latitude,
      lng: longitude,
      zoom: ZOOM_LOCAL,
    });
  };

  const handleCallback = React.useCallback(handleSucessoPosicaoAtual, [
    obterLocalizacaoUsuarioCallback,
  ]);

  const sucessoCallback = React.useCallback(
    (posicao: GeolocationPosition) => {
      mapa && handleCallback(mapa, posicao);
    },
    [mapa, handleCallback]
  );

  const handleErroPosicaoAtual = () => {
    // TODO: (ID) Criar um alerta solicitando ao usuario que ative a localizacao
    MixpanelClient.track(criarEventoLocalizacaoUsuarioNegada());
  };

  React.useEffect(() => {
    // TODO: (ID) Revisar se essas opcoes iniciais são necessárias
    const opcoes: PositionOptions = {
      // enableHighAccuracy: true,
      // maximumAge: 10,
    };

    // TODO: (ID) Isolar chamadas a API do browser na ACL
    navigator.geolocation.getCurrentPosition(
      sucessoCallback,
      handleErroPosicaoAtual,
      opcoes
    );
  }, [sucessoCallback]);

  return {
    posicaoUsuario,
    setPosicaoUsuario,
  };
};
