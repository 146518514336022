export const alternarItemColecao = <Tipo = any>(
  colecao: Tipo[],
  item: Tipo
) => {
  const index = colecao.indexOf(item);
  if (index === -1) {
    colecao.push(item);
  } else {
    colecao.splice(index, 1);
  }
};
