import { GeocodeResponse } from './geocode.acl';
import { Coordenadas } from '../coordenadas.domain';
import { Fronteiras } from '../fronteiras.domain';

export const obterFronteiras = (response: GeocodeResponse): Fronteiras => {
  const {
    bounds: { northeast, southwest },
  } = response.results[0].geometry;

  const sudoeste: Coordenadas = {
    latitude: southwest.lat,
    longitude: southwest.lng,
  };

  const nordeste: Coordenadas = {
    latitude: northeast.lat,
    longitude: northeast.lng,
  };

  return {
    sudoeste,
    nordeste,
  };
};
