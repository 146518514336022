import { ChangeEventHandler } from 'react';
import { obterOpcoesUf } from '../helpers';
import { UnidadeFederativa } from '../../../domain/unidade-federativa.domain';

interface SelectUnidadeFederativaProps {
  unidadeFederativa?: UnidadeFederativa | null;
  onChange: ChangeEventHandler;
}

export const SelectUnidadeFederativa = ({
  unidadeFederativa,
  onChange,
}: SelectUnidadeFederativaProps) => {
  const opcoesUf = obterOpcoesUf();

  return (
    <div className="input-group mb-3">
      <span className="input-group-text">Estado</span>
      <select
        className="form-select"
        value={unidadeFederativa?.sigla}
        onChange={onChange}
      >
        <option>Selecione</option>
        {opcoesUf}
      </select>
    </div>
  );
};
