import { municipios } from '../../data/municipios.data';
import { unidadesFederativas } from '../../data/unidades-federativas.data';
import { UnidadeFederativa } from '../../domain/unidade-federativa.domain';

export const obterOpcoesUf = () => {
  return unidadesFederativas.map((unidadeFederativa) => {
    return (
      <option
        value={unidadeFederativa.sigla}
        key={`opcoes-ufs-${unidadeFederativa.sigla}`}
      >
        {unidadeFederativa.nome}
      </option>
    );
  });
};

export const obterOpcoesMunicipioPorUf = (
  unidadeFederativa: UnidadeFederativa
) => {
  return municipios
    .filter(
      (municipio) =>
        municipio.unidadeFederativa.sigla === unidadeFederativa.sigla
    )
    .map((municipio) => {
      return (
        <option
          value={municipio.nome}
          key={`opcoes-municipios-${municipio.nome}`}
        >
          {municipio.nome}
        </option>
      );
    });
};
