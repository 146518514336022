import * as React from 'react';

import { UnidadeFederativa } from '../../domain/unidade-federativa.domain';
import { Municipio } from '../../domain/municipio.domain';

interface HomeHooks {
  municipio: Municipio | null;
  unidadeFederativa: UnidadeFederativa | null;
  setMunicipio: (m: Municipio | null) => void;
  setUnidadeFederativa: (uf: UnidadeFederativa | null) => void;
}

export const useHomeHooks = (): HomeHooks => {
  const [municipio, setMunicipio] = React.useState<Municipio | null>(null);
  const [unidadeFederativa, setUnidadeFederativa] =
    React.useState<UnidadeFederativa | null>(null);

  return {
    municipio: municipio,
    unidadeFederativa: unidadeFederativa,
    setMunicipio,
    setUnidadeFederativa,
  };
};
