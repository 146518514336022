import Geocode from 'react-geocode';
import { GeocodeResponse } from '../domain/acl/geocode.acl';
import { Coordenadas } from '../domain/coordenadas.domain';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY!);
Geocode.setLanguage('pt-br');

export const GeocodeClient = {
  doEndereco: async (endereco: string): Promise<GeocodeResponse> => {
    const response = await Geocode.fromAddress(endereco);
    return response as GeocodeResponse;
  },

  dasCoordenadas: async (
    coordenadas: Coordenadas
  ): Promise<GeocodeResponse> => {
    const response = await Geocode.fromLatLng(
      coordenadas.latitude.toFixed(20),
      coordenadas.longitude.toFixed(20)
    );

    return response as GeocodeResponse;
  },
};
