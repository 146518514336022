import * as React from 'react';
import { unidadesFederativas } from '../../data/unidades-federativas.data';
import { UnidadeFederativa } from '../../domain/unidade-federativa.domain';
import { Municipio } from '../../domain/municipio.domain';
import { municipios } from '../../data/municipios.data';
import { obterFronteiras } from '../../domain/acl/geocode.helpers';
import { ajustarFronteiras } from '../../domain/acl/google-maps.helpers';
import { MixpanelClient } from '../../services/mixpanel.service';
import {
  criarEventoMunicipioSelecionado,
  criarEventoUnidadeFederativaSelecionada,
} from './analytics';
import { GeocodeClient } from '../../services/geocode.service';

interface UseFormularioLocalizacaoArgs {
  mapa: google.maps.Map | null;
  setUnidadeFederativa: (uf: UnidadeFederativa | null) => void;
  setMunicipio: (m: Municipio | null) => void;
}

interface UseFormularioLocalizacaoHook {
  selecionarUnidadeFederativa: React.ChangeEventHandler;
  selecionarMunicipio: React.ChangeEventHandler;
}

export const useFormularioLocalizacao = ({
  mapa,
  setMunicipio,
  setUnidadeFederativa,
}: UseFormularioLocalizacaoArgs): UseFormularioLocalizacaoHook => {
  const selecionarUnidadeFederativa: React.ChangeEventHandler<
    HTMLSelectElement
  > = async (e) => {
    const ufSelecionada = unidadesFederativas.find(
      (it) => it.sigla === e.currentTarget.value
    );

    if (ufSelecionada) {
      const evento = criarEventoUnidadeFederativaSelecionada(ufSelecionada);
      MixpanelClient.track(evento);

      const response = await GeocodeClient.doEndereco(ufSelecionada.nome);

      const fronteiras = obterFronteiras(response);
      mapa && ajustarFronteiras(mapa, fronteiras);
    }

    setUnidadeFederativa(ufSelecionada ?? null);
    setMunicipio(null);
  };

  const selecionarMunicipio: React.ChangeEventHandler<
    HTMLSelectElement
  > = async (e) => {
    const municipioSelecionado = municipios.find(
      (it) => it.nome === e.currentTarget.value
    );

    if (municipioSelecionado) {
      const evento = criarEventoMunicipioSelecionado(municipioSelecionado);
      MixpanelClient.track(evento);

      const response = await GeocodeClient.doEndereco(
        `${municipioSelecionado.nome}, ${municipioSelecionado.unidadeFederativa.nome}`
      );

      const fronteiras = obterFronteiras(response);
      mapa && ajustarFronteiras(mapa, fronteiras);
    }

    setMunicipio(municipioSelecionado ?? null);
  };

  return {
    selecionarUnidadeFederativa,
    selecionarMunicipio,
  };
};
