import * as React from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { alternarItemColecao } from '../../helpers/alternar-item-colecao';
import { CategoriaHabilitacao } from '../../../domain/categoria-habilitacao.domain';
import { ServicoHabilitacao } from '../../../domain/servicos-habilitacao.domain';
import { MixpanelClient } from '../../../services/mixpanel.service';
import { criarEventoCancelarEnvioSolicitacaoOrcamento } from './analytics';
import { FormsparkClient } from '../../../services/formspark.client';
import { EventoAnalytics } from '../../../domain/evento-analytics.domain';

interface ModalState {
  exibir: boolean;
}

interface ModalActions {
  toggleExibir: () => void;
}

interface EmpresaState {
  uuid: string;
  rotulo: string;
  categorias: CategoriaHabilitacao[];
  servicos: ServicoHabilitacao[];
  email?: string;
}

interface EmpresaActions {
  setEmpresa: (e: EmpresaState) => void;
}

interface ConsumidorState {
  nome: string;
  email: string;
  telefone: string;
  maioridade: boolean;
  categorias: string[];
  servicos: string[];
}

interface ConsumidorActions {
  setNome: (nome: string) => void;
  setEmail: (email: string) => void;
  setTelefone: (telefone: string) => void;
  toggleMaioridade: () => void;
  toggleCategoria: (categorias: string) => void;
  toggleServico: (servicos: string) => void;
}

interface State {
  modal: ModalState;
  consumidor: ConsumidorState;
  empresa?: EmpresaState;
}

type CombinedActions = ModalActions & ConsumidorActions & EmpresaActions;

export const useAppModalStore = create(
  immer<State & CombinedActions>((set) => ({
    modal: {
      exibir: false,
    },
    consumidor: {
      nome: '',
      email: '',
      telefone: '',
      maioridade: false,
      categorias: [],
      servicos: [],
    },
    toggleExibir: () => {
      set((state) => {
        state.modal.exibir = !state.modal.exibir;
      });
    },
    setEmpresa: (empresa) =>
      set((state) => {
        state.empresa = empresa;
      }),
    setNome: (nome) =>
      set((state) => {
        state.consumidor.nome = nome;
      }),
    setEmail: (email) =>
      set((state) => {
        state.consumidor.email = email;
      }),
    setTelefone: (telefone) =>
      set((state) => {
        state.consumidor.telefone = telefone;
      }),
    toggleMaioridade: () =>
      set((state) => {
        state.consumidor.maioridade = !state.consumidor.maioridade;
      }),
    toggleCategoria: (categoria) =>
      set((state) =>
        alternarItemColecao(state.consumidor.categorias, categoria)
      ),
    toggleServico: (servico) =>
      set((state) => alternarItemColecao(state.consumidor.servicos, servico)),
  }))
);

interface ModalHook {
  handleFecharModal: () => void;
  handleEnviarSolicitacao: () => void;
  setAntiSpamToken: (token: string) => void;
}

type UseAppModalHook = ModalHook & State & CombinedActions;

export const useAppModal = (): UseAppModalHook => {
  const {
    modal,
    consumidor,
    empresa,
    toggleExibir,
    setEmpresa,
    setNome,
    setEmail,
    setTelefone,
    toggleMaioridade,
    toggleCategoria,
    toggleServico,
  } = useAppModalStore();

  const [antiSpamToken, setAntiSpamToken] = React.useState<string | null>(null);

  const handleFecharModal = () => {
    const evento = criarEventoCancelarEnvioSolicitacaoOrcamento({
      nomeEmpresa: empresa?.rotulo,
      uuidEmpresa: empresa?.uuid,
    });
    MixpanelClient.track(evento);
    toggleExibir();
  };

  const handleEnviarSolicitacao = async () => {
    // TODO: (ID) Generalizar
    if (!empresa) {
      const eventoErro: EventoAnalytics = {
        rotulo: 'Erro inesperado',
        nome: 'auto-escola-erro-inesperado',
        acao: 'Envio de Solicitação de Orçamento',
        payload: {
          mensagem: 'Não há uma empresa selecionada.',
        },
      };
      MixpanelClient.track(eventoErro);
      throw Error(
        'É obrigatório selecionar uma empresa antes de solicitar orçamento.'
      );
    }

    if (!antiSpamToken) {
      const eventoErro: EventoAnalytics = {
        rotulo: 'Erro inesperado',
        nome: 'auto-escola-erro-inesperado',
        acao: 'Envio de Solicitação de Orçamento',
        payload: {
          mensagem: 'Token anti-spam não encontrado.',
        },
      };
      MixpanelClient.track(eventoErro);
      throw Error('Tente solucionar o captcha novamente.');
    }

    const eventoDisparo: EventoAnalytics = {
      rotulo: 'Resposta do Envio de E-mail',
      nome: 'Resposta do Envio de E-mail',
      acao: 'Resposta do Envio de E-mail',
      payload: {
        dados: 'dados',
      },
    };
    MixpanelClient.track(eventoDisparo);

    const resposta = await FormsparkClient.enviarSolicitacaoOrcamento({
      dadosFormulario: {
        ...consumidor,
        nomeEmpresa: empresa.rotulo,
        emailEmpresa: empresa.email,
      },
      configuracao: {
        antiSpamToken,
        assunto: `Solicitação de Orçamento | ${empresa.rotulo}`,
        template: {
          titulo: 'Solicitação Orçamento',
          rodape: 'Teste Rodapé',
        },
      },
    });

    console.log({ resposta });

    const eventoResposta: EventoAnalytics = {
      rotulo: 'Resposta do Envio de E-mail',
      nome: 'Resposta do Envio de E-mail',
      acao: 'Resposta do Envio de E-mail',
      payload: {
        status: resposta.status,
        data: resposta.data,
      },
    };

    MixpanelClient.track(eventoResposta);
  };

  return {
    modal,
    consumidor,
    empresa,
    toggleExibir,
    setEmpresa,
    setNome,
    setEmail,
    setTelefone,
    toggleMaioridade,
    toggleCategoria,
    toggleServico,
    handleFecharModal,
    handleEnviarSolicitacao,
    setAntiSpamToken,
  };
};
