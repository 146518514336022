import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHomeHooks } from './home.hooks';
import { useFormularioLocalizacao } from './use-formulario-localizacao.hooks';
import { usePosicaoAtual } from './use-posicao-atual.hooks';
import { Callout } from './components/callout.component';
import { useDisplayMap } from '../../shared/components/map/use-display-map.hooks';
import { DisplayMap } from '../../shared/components/map/display-map.component';
import { criarEventoVisualizacaoPagina } from '../../shared/events';
import { MixpanelClient } from '../../services/mixpanel.service';

const HomeView = () => {
  const { municipio, unidadeFederativa, setMunicipio, setUnidadeFederativa } =
    useHomeHooks();

  const displayMap = useDisplayMap({
    municipio,
    unidadeFederativa,
  });

  const { posicaoUsuario } = usePosicaoAtual({
    mapa: displayMap.mapa,
    setMunicipio,
    setUnidadeFederativa,
  });

  const { selecionarUnidadeFederativa, selecionarMunicipio } =
    useFormularioLocalizacao({
      mapa: displayMap.mapa,
      setMunicipio,
      setUnidadeFederativa,
    });

  React.useEffect(() => {
    const evento = criarEventoVisualizacaoPagina('home.view');
    MixpanelClient.track(evento);
  }, []);

  return (
    <Row className="flex-lg-row-reverse align-items-center g-5 py-5">
      <Col lg={5} sm={12} xs={12}>
        <Callout
          municipio={municipio}
          unidadeFederativa={unidadeFederativa}
          selecionarUnidadeFederativa={selecionarUnidadeFederativa}
          selecionarMunicipio={selecionarMunicipio}
        />
      </Col>

      <Col lg={6} sm={8} md={10}>
        <DisplayMap
          referenciaInicial={posicaoUsuario}
          displayMap={displayMap}
        />
      </Col>
    </Row>
  );
};

export { HomeView };
