import { styled } from 'styled-components';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { OPCOES_CIRCULO } from './helpers';
import { UseDisplayHook } from './use-display-map.hooks';
import { MarcadoresLocais } from './marcadores-locais.component';
import { ReferenciaGoogleMaps } from '../../../domain/acl/google-maps.acl';

const MapaContainer = styled.div`
  height: 70vh;
  width: 40vw;
  opacity: 0.9;

  & > .map-container {
    height: 100%;
    width: 100%;
  }
`;

interface DisplayMapProps {
  referenciaInicial?: ReferenciaGoogleMaps | null;
  displayMap: UseDisplayHook;
}

const DisplayMap = ({ referenciaInicial, displayMap }: DisplayMapProps) => {
  const {
    carregando,
    onGoogleMapLoad,
    onGoogleMapUnmount,
    raioEmMetros,
    marcadores,
  } = displayMap;

  // TODO: (ID) Investigar excesso de re-renders
  console.log('re-render');

  return (
    <MapaContainer>
      {carregando ? (
        <h1>Carregando...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          onLoad={onGoogleMapLoad}
          onUnmount={onGoogleMapUnmount}
          options={{
            mapTypeControl: false,
          }}
        >
          {referenciaInicial && (
            <>
              <Marker
                position={referenciaInicial}
                options={{ label: 'Seu local' }}
              />
              <Circle
                center={referenciaInicial}
                radius={raioEmMetros}
                options={OPCOES_CIRCULO}
              />
            </>
          )}
          <MarcadoresLocais marcadores={marcadores} />
        </GoogleMap>
      )}
    </MapaContainer>
  );
};

export { DisplayMap };
