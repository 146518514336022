import { EventoAnalytics } from '../../domain/evento-analytics.domain';
import { Municipio } from '../../domain/municipio.domain';
import { UnidadeFederativa } from '../../domain/unidade-federativa.domain';

interface PayloadEventoLocalizacaoUsuario {
  permitido: boolean;
  erro?: boolean;
}

export const criarEventoLocalizacaoUsuarioPermitida =
  (): EventoAnalytics<PayloadEventoLocalizacaoUsuario> => {
    return {
      rotulo: 'Localização do Usuário',
      nome: 'auto-localizacao-usuario',
      acao: 'Localização do Usuário Permitida',
      payload: {
        permitido: true,
      },
    };
  };

export const criarEventoLocalizacaoUsuarioNegada =
  (): EventoAnalytics<PayloadEventoLocalizacaoUsuario> => {
    return {
      rotulo: 'Localização do Usuário',
      nome: 'auto-localizacao-usuario',
      acao: 'Localização do Usuário Negada',
      payload: {
        permitido: false,
      },
    };
  };

interface PayloadEventoUnidadeFederativaSelecionada {
  unidadeFederativa: string;
}

export const criarEventoUnidadeFederativaSelecionada = (
  unidadeFederativa: UnidadeFederativa
): EventoAnalytics<PayloadEventoUnidadeFederativaSelecionada> => {
  return {
    rotulo: 'Seleção de UF para Localizar Auto-Escolas',
    nome: 'auto-selecao-uf-localizar-auto-escolas',
    acao: 'UF Selecionada',
    payload: {
      unidadeFederativa: unidadeFederativa.nome,
    },
  };
};

interface PayloadEventoMunicipioSelecionado {
  municipio: string;
  capital: boolean;
  unidadeFederativa: string;
}

export const criarEventoMunicipioSelecionado = (
  municipio: Municipio
): EventoAnalytics<PayloadEventoMunicipioSelecionado> => {
  return {
    rotulo: 'Seleção de Município para Localizar Auto-Escolas',
    nome: 'auto-selecao-municipio-localizar-auto-escolas',
    acao: 'Município Selecionado',
    payload: {
      municipio: municipio.nome,
      capital: municipio.capital,
      unidadeFederativa: municipio.unidadeFederativa.nome,
    },
  };
};
