import { CATEGORIAS_CNH } from '../domain/categoria-habilitacao.domain';
import { ReferenciaLocalizada } from '../domain/referencia-localizada.domain';
import { SERVICOS_HABILITACAO } from '../domain/servicos-habilitacao.domain';

export const locais: ReferenciaLocalizada[] = [
  {
    uuid: 'ede45c8f-9103-44d5-b632-2b672b196601',
    rotulo: 'CFC Inter Auto Escola',
    coordenadas: {
      latitude: -12.886111211252217,
      longitude: -38.31011031439074,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    telefone: ['07133697447'],
    endereco:
      'Av. Luiz Tarquínio Pontes - Jardim Aeroporto, Lauro de Freitas - BA, 42700-000',
  },
  {
    uuid: '84605f02-d7ce-4f65-8aaf-f0f0ac6594fb',
    rotulo: 'Auto Escola Lauro de Freitas',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RENOVACAO,
      SERVICOS_HABILITACAO.MUDANCA_CATEGORIA,
      SERVICOS_HABILITACAO.RECONHECIMENTO_PERCURSO,
    ],
    coordenadas: {
      latitude: -12.892125423436651,
      longitude: -38.32662776916185,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    telefone: ['(71) 3646-9213', '071987323209'],
    whatsapp: '071987323209',
    email: 'autoescolalaurodefreitas@gmail.com',
    endereco:
      'Av. Santos Dumont, 3257 - Centro, Lauro de Freitas - BA, 42700-000',
    facebook:
      'https://www.facebook.com/Autoescola-LF-104910474404096/?modal=admin_todo_tour',
  },
  {
    uuid: 'dc08a8e1-9983-4428-b5b9-403e537e5b55',
    rotulo: 'Auto Escola Expresso',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RENOVACAO,
      SERVICOS_HABILITACAO.ATUALIZACAO_HABILITACAO,
      SERVICOS_HABILITACAO.TREINAMENTO_HABILITADOS,
    ],
    coordenadas: {
      latitude: -12.882363684763085,
      longitude: -38.30764853546414,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    facebook: 'https://www.facebook.com/CFCExpresso',
    telefone: ['07135086382', '07130241146'],
    whatsapp: ['071993325475'],
    email: 'autoescolaexpresso@gmail.com',
    formatoAula: ['PRESENCIAL'],
    endereco:
      'Av. Luiz Tarquínio Pontes, 1949 - Belo Horizonte, Lauro de Freitas - BA, 42700-000',
  },
  {
    uuid: '9caca9d9-6fa3-4e3c-8a1e-a4ca328ea844',
    rotulo: 'Auto Escola Villas',
    categoriasHabilitacao: [
      CATEGORIAS_CNH.A,
      CATEGORIAS_CNH.B,
      CATEGORIAS_CNH.D,
    ],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.MUDANCA_CATEGORIA,
      SERVICOS_HABILITACAO.TREINAMENTO_HABILITADOS,
      SERVICOS_HABILITACAO.RECONHECIMENTO_PERCURSO,
    ],
    coordenadas: {
      latitude: -12.879477042196909,
      longitude: -38.30614649839109,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    telefone: ['07133792384', '(71) 3379-6658'],
    whatsapp: '071987961249',
    formatoAula: ['DIGITAL_NAO_INFORMADO'],
    endereco:
      'Av. Luiz Tarquínio Pontes, 2341 - loja 2 - Vilas do Atlântico, Lauro de Freitas - BA, 42700-000',
    instagram: 'https://www.instagram.com/autoescolavillas/',
    tiktok: '@autoescolavillas',
  },
  {
    uuid: 'e1801f73-f46e-4785-aa8c-a992da4db778',
    rotulo: 'Matriz Auto Escola',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    formatoAula: ['PRESENCIAL'],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.MUDANCA_CATEGORIA,
    ],
    coordenadas: {
      latitude: -12.897451048401626,
      longitude: -38.32560618450679,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    website: 'http://www.matrizautoescola.com.br/',
    email: 'contato@matrizautoescola.com.br',
    telefone: ['07130511499', '(71) 3378-4322', '07199614-5024'],
    whatsapp: ['071999764042', '7199357373'],
    facebook:
      'https://www.facebook.com/pages/Auto-escola%20Matriz/204114229966886/',
    instagram: 'https://www.instagram.com/matrizautoescolaoficial/',
    endereco:
      'Em frente à igreja Católica - Rua Romualdo de Brito, 34 - 2 Andar - Centro, Lauro de Freitas - BA, 42702-710',
  },
  {
    uuid: '6df350d6-d0f0-4bf7-b7d5-f3f563ba189d',
    rotulo: 'Auto Escola F1',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RECICLAGEM,
      SERVICOS_HABILITACAO.TREINAMENTO_HABILITADOS,
    ],
    coordenadas: {
      latitude: -12.886476111054458,
      longitude: -38.29712671519668,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    website: 'https://www.autoescolaf1.com.br/',
    telefone: ['07135087792'],
    email: 'contato@autoescolaf1.com.br',
    endereco:
      'Av. Praia de Itapuã, 1369 - Qd. 27, Lote 04 - Vilas do Atlântico, Lauro de Freitas - BA, 42707-650',
  },
  {
    uuid: '3780856e-388a-40dc-a6ba-bba486130ce5',
    rotulo: 'CFC Decisão',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RECICLAGEM,
      SERVICOS_HABILITACAO.ATUALIZACAO_HABILITACAO,
      SERVICOS_HABILITACAO.RENOVACAO,
    ],
    formatoAula: ['PRESENCIAL'],
    coordenadas: {
      latitude: -12.89034142052995,
      longitude: -38.32498408450679,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    telefone: ['07130260091'],
    endereco:
      'Est do Côco, 3475 - Jardim do Jockey, Lauro de Freitas - BA, 42700-000',
    instagram: 'https://instagram.com/autoescoladecisao?igshid=YmMyMTA2M2Y=',
    whatsapp: ['07130260091', '71982141461', '71982141661'],
  },
  {
    uuid: 'd0fa641d-5bb4-4968-a92c-5d999ccc9c05',
    rotulo: 'Auto Escola Blitz',
    coordenadas: {
      latitude: -12.911489979482356,
      longitude: -38.35325392683431,
    },
    nomeCidade: 'Salvador',
    siglaUf: 'BA',
    telefone: ['07130130223'],
    endereco: 'Praça da Matriz, 5 - São Cristóvão, Salvador - BA, 41510-100',
  },
  {
    uuid: '70f1566e-2072-4b4e-8fb1-01f3acddd09e',
    rotulo: 'Auto Escola Itinga',
    coordenadas: {
      latitude: -12.895984880512376,
      longitude: -38.33828225381691,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    telefone: ['07121362792'],
    endereco:
      'Av. Fortaleza, 795 - Lot. Jardim Metropole, Lauro de Freitas - BA, 42700-000',
  },
  {
    uuid: '67ef144d-98ea-4aa9-aaf0-67cc58c60b40',
    rotulo: 'Auto Escola Ideal.com',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
    ],
    coordenadas: {
      latitude: -12.893976894088077,
      longitude: -38.34173693908491,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    instagram: 'https://instagram.com/cfcideal.com_?igshid=ZDdkNTZiNTM=',
    telefone: ['07131901634'],
    whatsapp: ['071983311518', '071986426939'],
    endereco: 'Av. Fortaleza, 1233 - Itingá, Lauro de Freitas - BA, 42738-595',
  },
  {
    uuid: 'e254a50b-abce-4fef-a685-ce682d6c08bf',
    rotulo: 'Auto Escola Frois CFC Via Express',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
    ],
    coordenadas: {
      latitude: -12.917101937398161,
      longitude: -38.352220069161845,
    },
    nomeCidade: 'Salvador',
    siglaUf: 'BA',
    website: 'https://auto-esc0la-frois-cfc-via-express.negocio.site/',
    telefone: ['07130550995'],
    whatsapp: '71986401359',
    endereco:
      'Av. São Cristóvão, 17430 - São Cristóvão, Salvador - BA, 41510-333',
  },
  {
    uuid: '86316388-f8ab-4937-bf6b-0fb1f8cdda41',
    rotulo: 'Auto Escola Veja',
    coordenadas: {
      latitude: -12.913379097532067,
      longitude: -38.35123301622812,
    },
    nomeCidade: 'Salvador',
    siglaUf: 'BA',
    telefone: ['07132523199'],
    endereco: 'Av. São Cristóvão, 21 - São Cristóvão, Salvador - BA, 41510-333',
  },
  {
    uuid: '37aadd82-188b-4b0b-99b4-bac139657f01',
    rotulo: 'Auto Escola Galiléia',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RENOVACAO,
      SERVICOS_HABILITACAO.RECICLAGEM,
    ],
    formatoAula: ['DIGITAL_LIVE'],
    email: 'atendimentocfcgalileia@gmail.com',
    coordenadas: {
      latitude: -12.891326138173257,
      longitude: -38.34934119985173,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    facebook: 'https://www.facebook.com/galileiaautoescola/',
    telefone: ['07130261728'],
    whatsapp: ['71 98741-4523', '07132513790'],
    endereco:
      'Dentro do POP Shop, R. São Cristóvão, 1732 - Loja 19 - Itingá, Lauro de Freitas - BA, 42739-005',
  },
  {
    uuid: 'eac82a76-e84d-44af-8f79-c70329722033',
    rotulo: 'Auto Escola União',
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    formatoAula: ['PRESENCIAL'],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.TRANSPORTE_COLETIVO_PASSAGEIROS,
      SERVICOS_HABILITACAO.TRANSPORTE_ESCOLAR,
      SERVICOS_HABILITACAO.ESPCECIALIZACAO_VEICULOS_PASSAGEIROS,
      SERVICOS_HABILITACAO.TRANSPORTE_RODOVIARIO_PRODUTOS_PERIGOSOS,
      SERVICOS_HABILITACAO.VEICULOS_EMERGENCIA,
      SERVICOS_HABILITACAO.RECICLAGEM,
      SERVICOS_HABILITACAO.RENOVACAO,
      SERVICOS_HABILITACAO.RECLASSIFICACAO,
      SERVICOS_HABILITACAO.CURSO_TEORICO,
    ],
    coordenadas: {
      latitude: -12.947824866138529,
      longitude: -38.36136938450678,
    },
    nomeCidade: 'Salvador',
    siglaUf: 'BA',
    website: 'https://www.cfcautoescolauniao.com.br/',
    telefone: ['07133754034'],
    endereco: 'Ladeira do Abaeté, S/N - Itapuã, Salvador - BA, 41610-525',
  },
  {
    uuid: '96d138f8-d273-4a6b-8931-f01e2660f34c',
    rotulo: 'Litorânea CFC',
    formatoAula: ['DIGITAL_NAO_INFORMADO'],
    categoriasHabilitacao: [CATEGORIAS_CNH.A, CATEGORIAS_CNH.B],
    servicosHabilitacao: [
      SERVICOS_HABILITACAO.PRIMEIRA_HABILITACAO,
      SERVICOS_HABILITACAO.RENOVACAO,
      SERVICOS_HABILITACAO.ADICAO_CATEGORIA,
      SERVICOS_HABILITACAO.RECICLAGEM,
      SERVICOS_HABILITACAO.ATUALIZACAO_HABILITACAO,
      SERVICOS_HABILITACAO.TREINAMENTO_HABILITADOS,
      SERVICOS_HABILITACAO.MOTO_TAXI,
      SERVICOS_HABILITACAO.MOTO_FRETISTA,
      SERVICOS_HABILITACAO.DIRECAO_DEFENSIVA,
    ],
    coordenadas: {
      latitude: -12.874299039762535,
      longitude: -38.305474499851734,
    },
    nomeCidade: 'Lauro de Freitas',
    siglaUf: 'BA',
    instagram: 'https://www.instagram.com/autoescola.litoranea/',
    telefone: ['07130261057'],
    email: 'litoraneacfc@hotmail.com',
    whatsapp: ['71 99228-1001', '071987902819', '071991703828'],
    endereco:
      'Av. Luiz Tarquínio Pontes, 2901 - Granjas Reunidas Concórdia, Lauro de Freitas - BA, 42700-000',
  },
];
