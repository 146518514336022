import { InfoWindow, Marker } from '@react-google-maps/api';
import { MarcadorMapa } from './display-map.types';
import { MixpanelClient } from '../../../services/mixpanel.service';
import {
  criarEventoClickBotaoAbrirModalSolicitarOrcamento,
  criarEventoClickBotaoAbrirModalSouProprietario,
  criarEventoClickEmReferenciaMapa,
} from '../../events';
import { useAppModalStore } from '../app-modal/app-modal.hooks';

interface MarcadoresLocaisProps {
  marcadores: MarcadorMapa[];
}

export const MarcadoresLocais = ({ marcadores }: MarcadoresLocaisProps) => {
  const setEmpresa = useAppModalStore((state) => state.setEmpresa);
  const toggleExibirModal = useAppModalStore((state) => state.toggleExibir);

  return (
    <>
      {marcadores.map((marcador) => {
        const categorias = marcador.categoriasHabilitacao
          ?.map((it) => it.sigla)
          .join(', ');

        const servicos = marcador.servicosHabilitacao.map((it) => (
          <li key={`${marcador.rotulo}-${it.nome}`}>{it.nome}</li>
        ));

        return (
          <Marker
            position={{
              lat: marcador.posicao.latitude,
              lng: marcador.posicao.longitude,
            }}
            onClick={() => {
              const evento = criarEventoClickEmReferenciaMapa(marcador);
              MixpanelClient.track(evento);
              marcador.painelInfo.alternarAberto();
            }}
            key={`marker-for-${marcador.rotulo}`}
          >
            {marcador.painelInfo.aberto && (
              <InfoWindow onCloseClick={marcador.painelInfo.alternarAberto}>
                <>
                  <h6>{marcador.rotulo}</h6>
                  <div>{`Categorias: ${categorias}`}</div>
                  <div>Serviços:</div>
                  <ul>{servicos}</ul>
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-small m-1"
                      onClick={() => {
                        const evento =
                          criarEventoClickBotaoAbrirModalSolicitarOrcamento(
                            marcador
                          );
                        MixpanelClient.track(evento);
                        setEmpresa({
                          uuid: marcador.uuid,
                          rotulo: marcador.rotulo,
                          servicos: marcador.servicosHabilitacao,
                          categorias: marcador.categoriasHabilitacao,
                        });
                        toggleExibirModal();
                      }}
                    >
                      Solicitar Orçamento
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-small"
                      onClick={() => {
                        const evento =
                          criarEventoClickBotaoAbrirModalSouProprietario(
                            marcador
                          );
                        MixpanelClient.track(evento);
                      }}
                    >
                      Sou Proprietário
                    </button>
                  </div>
                </>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </>
  );
};
