import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { ServicoHabilitacao } from '../../../domain/servicos-habilitacao.domain';
import { useAppModal } from './app-modal.hooks';
import { Turnstile } from '@marsidev/react-turnstile';

// TODO: (ID) Dá pra generalizar
const construirGridListaServicos = (
  servicos: ServicoHabilitacao[],
  tamanhoLinha: number,
  handler: (n: string) => void
) => {
  const linhas = servicos.reduce(
    (acumulador: Array<any>, servico: ServicoHabilitacao) => {
      const indice = acumulador.length - 1;
      const linha = acumulador.at(indice) as Array<ServicoHabilitacao>;

      if (linha && linha.length === tamanhoLinha) {
        const novaLinha = [];
        novaLinha.push(servico);
        acumulador.push(novaLinha);
      } else {
        linha.push(servico);
      }

      return acumulador;
    },
    [[]]
  );

  return (
    <>
      {linhas.map((linha: Array<ServicoHabilitacao>, indice) => {
        return (
          <Row key={`linha-${indice}`}>
            {linha.map((it) => {
              return (
                <Col key={`col-servico-${it.nome}`}>
                  <InputGroup className="mb-3 flex-nowrap" size="sm">
                    <InputGroup.Checkbox
                      name="servico"
                      aria-label={`Serviço ${it}`}
                      onClick={() => handler(it.nome)}
                      value={it.nome}
                    />
                    <InputGroup.Text>{it.nomeCurto ?? it.nome}</InputGroup.Text>
                  </InputGroup>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </>
  );
};

export const AppModal = () => {
  const {
    modal,
    consumidor,
    empresa,
    setNome,
    setEmail,
    setTelefone,
    setAntiSpamToken,
    toggleMaioridade,
    toggleCategoria,
    toggleServico,
    handleFecharModal,
    handleEnviarSolicitacao,
  } = useAppModal();

  return (
    <Modal
      size="lg"
      centered={true}
      show={modal.exibir}
      onHide={handleFecharModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{empresa?.rotulo}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Sobre Você</h5>

        <Container fluid="md">
          <Row>
            <Col>
              <InputGroup className="mb-3 flex-nowrap">
                <InputGroup.Text>Nome</InputGroup.Text>
                <Form.Control
                  aria-label="Nome"
                  placeholder="João da Silva"
                  onChange={(e) => setNome(e.currentTarget.value)}
                  value={consumidor.nome}
                  required
                />
              </InputGroup>
            </Col>

            <Col>
              <InputGroup className="mb-3 flex-nowrap">
                <InputGroup.Text>E-mail</InputGroup.Text>
                <Form.Control
                  aria-label="E-mail"
                  placeholder="joao.silva@gmail.com"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  value={consumidor.email}
                  required
                />
              </InputGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputGroup className="mb-3 flex-nowrap">
                <InputGroup.Text>Telefone</InputGroup.Text>
                <Form.Control
                  aria-label="Telefone"
                  placeholder="(71) 99999-9999"
                  onChange={(e) => setTelefone(e.currentTarget.value)}
                  value={consumidor.telefone}
                />
              </InputGroup>
            </Col>

            <Col>
              <InputGroup className="mb-3 flex-nowrap">
                <InputGroup.Text>Completou 18 anos?</InputGroup.Text>
                <InputGroup.Checkbox
                  aria-label="Seleção da resposta completou 18 anos"
                  onChange={() => toggleMaioridade()}
                  value={consumidor.maioridade}
                />
              </InputGroup>
            </Col>
          </Row>
        </Container>

        <h5>Sobre sua Habilitação</h5>

        <h6>Categorias desejadas (info)</h6>

        <Container>
          <Row>
            {empresa?.categorias.map((it) => {
              return (
                <Col
                  xs={4}
                  sm={3}
                  md={2}
                  lg={1}
                  key={`col-categoria-${it.sigla}`}
                >
                  <InputGroup className="mb-3 flex-nowrap" size="sm">
                    <InputGroup.Checkbox
                      name="categorias"
                      aria-label={`Categoria ${it}`}
                      onClick={() => toggleCategoria(it.sigla)}
                      value={it}
                    />
                    <InputGroup.Text>{it.sigla}</InputGroup.Text>
                  </InputGroup>
                </Col>
              );
            })}
          </Row>
        </Container>

        <h6>Serviços desejados</h6>

        <Container fluid="sm">
          {construirGridListaServicos(
            empresa?.servicos ?? [],
            2,
            toggleServico
          )}
        </Container>

        <Turnstile
          siteKey="0x4AAAAAAAInC6ICn1UrVS1M"
          onSuccess={(token) => setAntiSpamToken(token)}
          onError={() => console.log('turnstile error')}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleFecharModal}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={() => handleEnviarSolicitacao()}>
          Solicitar Orçamento
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
